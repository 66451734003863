import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import parse from "html-react-parser"
import Header from "../components/Header/Header"
import { PageBreadcrumbs } from "../components/common/site/utils"
import Breadcrumbs from "../components/BreadCrumbs/DetailsBreadCrumbs"
import Footer from "../components/Footer/Footer"
import SEO from "../components/Seo/seo"
import { getWhatsAppURL } from "../common/misc"

const TeamDetailsTemplate = ({ data }) => {
  const teamDetail = data.glstrapi.team

  return (
    <div className="team-details-page">
      <Header />
      <SEO
        title={teamDetail.seo_meta ? teamDetail.seo_meta.title : teamDetail?.name+" "+teamDetail?.designation}
        description={teamDetail.seo_meta ? teamDetail.seo_meta.description : "Get to know about "+teamDetail?.name+" here. Contact one of property consultants for assistance in finding the right properties for you."}
      />
      <Breadcrumbs
        breadcrumbs={PageBreadcrumbs("our-team")}
        title={teamDetail?.name}
      />

      <section className="team-details-wrapper">
        <Container>
          <Row>
            <Col lg={5}>
              <div className="team-details-img-zoom">
                <img src={teamDetail?.image?.url} />
              </div>
            </Col>
            <Col lg={1}></Col>
            <Col lg={6}>
              <h1 className="team-details-heading">{teamDetail?.name}</h1>
              <div className="team-details-desig">
                {teamDetail?.designation}
              </div>
              <div className="d-flex justify-content-between team-details-btn-wrapper">
                <Link
                  to="/contact-omhome/"
                  className="btn btn-black"
                  state={{
                    teamName: teamDetail?.name,
                    teamEmail: teamDetail.email,
                  }}
                >
                  <i class="icon icon-property-envelope"></i>{" "}
                  <span className="d-md-flex d-none">Enquire</span>
                </Link>
                <a href={`tel:${teamDetail?.mobile_no}`} className="btn btn-transparent">
                  <i class="icon icon-property-phone"></i>{" "}
                  <span className="d-md-flex d-none">
                    {teamDetail?.mobile_no}
                  </span>
                </a>
                <a href={getWhatsAppURL(teamDetail?.mobile_no)} className="btn btn-transparent" target={"_blank"}>
                  <i class="icon icon-property-whatsapp"></i>{" "}
                  <span className="d-md-flex d-none">Whatsapp</span>
                </a>
              </div>
              <p className="team-content">{parse(teamDetail?.content)}</p>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </div>
  )
}

export default TeamDetailsTemplate

export const pageQuery = graphql`
  query GetTeamsPage($articleId: ID!) {
    glstrapi {
      team(id: $articleId, publicationState: LIVE) {
        id
        seo_meta {
          title
          description
        }
        name
        designation
        slug
        email
        mobile_no
        content
        image {
          url
        }
      }
    }
  }
`
